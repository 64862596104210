.main-selectedHud {
    width: 480px;

    text-align: center;
}

.box_Choosed {
    display: inline-block;
    width: 40px;

    color: #000;
    background-color: #f5f5f5;

    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    box-shadow: 0 0 10px #000;
}

.messageBox_Button {
    position: absolute;
    text-align: center;
    width: 300px;
    left: 50%;
    translate: -50% 0;
    top : 166px;
}

.messageBox_Link:link, .messageBox_Link:visited {
    color:#2F4FFD;
    text-decoration: none;
}

.messageBox_Popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    -webkit-transition: opacity 400ms ease-in;
    -moz-transition: opacity 400ms ease-in;
    transition: opacity 400ms ease-in;
    pointer-events: none;
    z-index: 1000;
}

.messageBox_Popup:target {
    opacity: 1;
    pointer-events: auto;
}

.messageBox_Popup>div {
    position: absolute;
    width : 300px;
    height : 200px;
    left : 50%;
    translate: -50% 0;
    top: 200px;
    border-radius: 12px;
    padding: 12px 20px 12px 20px;
    background-color: white;
    overflow: auto;
}




.btns_box
{
    display: inline-block;
    width: 310px;
    text-align: center;
}

.inline-block {
    display: inline-block;
}

.float-right {
    float: right;
}

.btn_selectable
{
    width: 310px;
    height : 40px;
    border-radius: 5px;
    border-color: lightgray;
    border-width: 2px;
    box-shadow: none !important;
}

.btn_selectable_half
{
    width: 150px;
    height : 40px;
    border-radius: 5px;
    /* border-color: lightgray; */
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.btn_prev
{
    width: 150px;
    height: 40px;
    border-radius: 5px;
    text-align: left;
    font-family: SUIT-Regular;
    
    background-color: none;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}


.bg_white {
    background-color: white;
    color: black;
}

.bg_blue{
    background-color: #2F4FFD;
    color: white;
}

.char {
    height : 200px;
    margin-top : 0px;
    width : auto;
    position: absolute;
    transform: translate(-50%, 0);
}

#char_hair{
    z-index: 10;
}

#char_body {
    z-index: 0;
}

#char_acc {
    z-index: 20;
}

.main-image{
    height: 200px;
}


.m4{
    margin-top: 4px;
    margin-bottom: 4px;
}

.t16{
    font-size: 16px;
}

.t18{
    font-size: 18px;
}

.t20{
    font-size: 20px;
}

.t22{
    font-size: 22px;
}

.w10{
    width: 10%;
}

.w35{
    width: 35%;
}

.w50{
    width: 50%;
}

.w480{
    width: 480px;
}

.w280{
    width: 280px;
}


.h10px{
    height: 10px;
}

.h20px{
    height: 20px;
}

.h30px{
    height: 30px;
}

.h40px{
    height: 40px;
}

.questionText
{
    font-size: 18px;
    color: black;
}