*{
    scrollbar-width: none !important;
    -ms-overflow-style: none;
    font-family: 'SUIT-Bold';
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

*::-webkit-scrollbar { 
    display: none;
    width: 0 !important;
}

@font-face {
    font-family: 'SUIT-Bold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

.App {
  text-align: center;
}

.App-header {
  background-color: white;
  height : 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: black;
}

.body{
    background-color: #ebebec;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    text-align: center;
    color: black;

    font-size: 18px;
    font-weight: 700;
}


/* MainPage */

.notDisplay {
    display: none;
}

.main-body {
    background-color: #ebebec;
    display: inline-block;
    width: 380px;
    margin-top: 0;
    top: 0;
    
    overflow-x: hidden;
    font-size: 18px;
    font-weight: 700;
}

.bg_blue {
    background-color: #2F4FFD;
}

.title_image{
    width: 280px;
    height: 424px;
    margin-top: 85px;
    margin-bottom: 53px;
}

.title_btn{
    width: 280px;
    height: 42px;

    border-radius: 5px;
    border-color: #2F4FFD;
    background-color: #2F4FFD;
    border-width: 2px;
    box-shadow: none !important;

    font-size: 16px;
    color: white;
    font-weight: 700;
    letter-spacing: 1px;
}

.title_btn_white {
    width: 276px;
    height: 52px;

    box-shadow: none !important;
    border-radius: 0px;
    border-color: #BEC0CB;
    background-color: #ffffff;
    border-width: 0.5px;
    text-align: center;
    
    text-align: center;

    font-size: 16px;
    color: #828282;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: center;
}

.title_btn_sml{
    width : 140px;
    height : 42px;
    margin-left : 140px;
    margin-top : 18px;
    border-radius: 0px;
    border-color: none;
    background-color: #ebebec;
    border-width: 0px;
    box-shadow: none !important;

    font-size: 16px;
    color: #828282;
    font-weight: 700;
    letter-spacing: 1px;
}

.title_btn_blue_sml {
    width: 140px;
    height: 42px;
    margin-left: 140px;
    margin-top: 18px;
    border-radius: 0px;
    border-color: #2F4FFD;
    background-color: #2F4FFD;
    border-width: 0px;
    box-shadow: none !important;

    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
    letter-spacing: 1px;
}

.call_image{
    width: 93px;
    height: 18px;
    margin-top: 121px;
    margin-bottom: 357px;
}

.call_image2 {
    width: 125px;
    height: 18px;
    margin-top: 121px;
    margin-bottom: 127px;
}

.talk_image {
    display: inline-block;
    width: 280px;
    height: 105px;
    margin-top: 0px;
    margin-bottom: 105px;
}

.text_in_talkImage{
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: center;

    margin-top: -180px;
}

.loading_Bg{
    position: absolute;
    width: 360px;
    height: 640px;
    background-color: #ebebec;
    transform: translate(-50%, 0);
}

.loading_div{
    position: absolute;
    width: 188px;
    height: 105px;
    margin-top: -48px;
    transform: translate(50%, 0);

}

.footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    background-color: white;
    text-align: right;
    color: #828282;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
}

.footer-text{
    margin-right: 20px;
    line-height: 14px;
}

.footer-text:link,
.footer-text:visited,
.footer-text:hover,
.footer-text:active {
    color: #828282;
    text-decoration: none;
}